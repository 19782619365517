import React from 'react';
import Layout from '../components/Layout';

function FourOhFourPage() {
  return (
    <Layout>
      <p>To varying extents, we're all lost (404).</p>
    </Layout>
  );
}

export default FourOhFourPage;
